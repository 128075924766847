import React, { useRef } from 'react';
import { Link } from 'react-router';
import {
  CARD_IMAGE_WIDTH,
  client,
  formatDate,
  photoUrl,
  nanoidToNumberInRange,
  VERTICAL_IMAGE_THRESHOLD,
  decodeHtml,
} from '../../utils';
import { PhotoCardMedia } from '@prp/shared';
import { useInView } from 'react-intersection-observer';
import { useUserActionContext } from '../../state/UserActionContext';
import useNavigationCallback from '../../hooks/useNavigationCallback';
import OrgSticker from '../support/OrgSticker';
import classNames from 'classnames';
import { getDisplayAge } from '@prp/shared/dist/utils';
import config from '../../config';

export interface PhotoCardProps {
  petMedia: PhotoCardMedia;
  fadeIn?: boolean;
  showOrgSticker?: boolean;
}

export const PhotoCard: React.FC<PhotoCardProps> = ({
  petMedia,
  fadeIn = true,
  showOrgSticker = true,
}) => {
  const { ref: ref1, inView: fullyInView } = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  const { ref: ref2, inView: partiallyInView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { petId, species, sex, age, url, lastMediaDate, name } = petMedia;
  const displayAge = getDisplayAge(species, age);

  const {
    viewedPhotoPetIds,
    addViewedPhotoPetId,
    clickedPhotoPetIds,
    addClickedPhotoPetId,
    animationStartedPhotoPetIds,
    addPhotoAnimationStarted,
    animationCompletedPhotoPetIds,
    addPhotoAnimationCompleted,
  } = useUserActionContext();

  const animationStarted = animationStartedPhotoPetIds.has(petId);
  const animationCompleted = animationCompletedPhotoPetIds.has(petId);

  const finishAnimation = () => {
    if (fadeIn && animationStarted && !animationCompleted) {
      addPhotoAnimationCompleted(petId);
    }
  };
  useNavigationCallback(finishAnimation);

  const logViewMutationRef = useRef(client.pets.logUserAction.useMutation());
  React.useEffect(() => {
    if (fullyInView && !viewedPhotoPetIds.has(petId)) {
      logViewMutationRef.current.mutate({
        item: 'photo',
        url,
        petId,
        action: 'view',
      });
      if (fadeIn) {
        addViewedPhotoPetId(petId);
      }
    }
  }, [fullyInView]);

  const animationDuration = nanoidToNumberInRange(petMedia.petId, 3, 7);
  React.useEffect(() => {
    if (fadeIn && partiallyInView) {
      addPhotoAnimationStarted(petId, animationDuration);
    }
  }, [partiallyInView]);

  const { primary, secondary } = petMedia.breeds;
  const breed = [primary, secondary].filter((breed) => breed).join(' / ');

  const goodWith: string[] = [];
  const badWith: string[] = [];

  Object.entries(petMedia.environment).forEach(([key, value]) => {
    if (value === true) {
      goodWith.push(key);
    } else if (value === false) {
      badWith.push(key);
    }
  });
  const goodWithText = goodWith.length ? `Yes: ${goodWith.join(', ')}` : '';
  const badWithText = badWith.length ? `No: ${badWith.join(', ')}` : '';
  const displayDate = formatDate(lastMediaDate);

  const isPredominantlyVertical =
    (petMedia.height ?? 0) / (petMedia.width ?? CARD_IMAGE_WIDTH) >
    VERTICAL_IMAGE_THRESHOLD;

  const animation =
    fadeIn && animationStarted && !animationCompleted
      ? `fadeInImage ${animationDuration}s forwards`
      : undefined;

  const style: React.CSSProperties = {
    width: '100%',
    height: '100%',

    objectFit: 'cover',
    objectPosition: isPredominantlyVertical ? 'top' : 'center',
    opacity: !fadeIn || animationCompleted ? 1 : 0,
    animation,
  };

  const imageUrl = photoUrl(url, petMedia.mediaType, CARD_IMAGE_WIDTH);
  if (!imageUrl) {
    return null;
  }
  const imageDomain = config.cdnUrl;
  const specialNeedsImage = `${imageDomain}/heart.png`;

  const logClick = () => {
    if (clickedPhotoPetIds.has(petId)) {
      return;
    }
    logViewMutationRef.current.mutate({
      item: 'photo',
      url,
      petId,
      action: 'click',
    });
    addClickedPhotoPetId(petId);
  };

  const altText = petMedia.altText ?? 'pet';
  const isCourtesyListing = isCurtsyListing(petMedia);
  const isSpecialNeeds = petMedia.isSpecialNeeds ?? false;

  return (
    <div
      ref={(node) => {
        ref1(node);
        ref2(node);
      }}
      data-id={petMedia.petId}
    >
      <div
        className={classNames('photo-card', {
          'text-blue-800': isCourtesyListing,
        })}
      >
        {showOrgSticker && <OrgSticker {...petMedia} />}
        <Link to={`/pet/detail/${petMedia.petId}`} onClick={logClick}>
          <div
            className={classNames(
              'flex flex-col justify-center items-stretch',
              'max-w-[min(70vw,300px)]',
              'w-[min(70vw,300px)]',
            )}
          >
            <div className="photo-card-header">
              <div className="breed-label fixed-text-length">{breed}</div>
              <div className="date-label">{displayDate}</div>
            </div>
            <div
              className={classNames(
                'flex justify-center items-center overflow-hidden bg-black',
                `w-[min(70vw,300px)]`,
                `h-[min(70vw,300px)]`,
              )}
            >
              <img src={imageUrl} alt={altText} loading="lazy" style={style} />
            </div>
            <div className="photo-card-bottom">
              <div className="pet-details-header">
                <div className="photo-card-body-extra">{displayAge}</div>
                <div className="photo-card-body-extra">{sex}</div>
              </div>
              <div className="photo-card-name fixed-text-length">
                {isSpecialNeeds && (
                  <img
                    src={specialNeedsImage}
                    alt="Special Needs"
                    className="absolute top-2 right-1 w-8 h-8 rounded-full"
                  />
                )}
                {decodeHtml(name)}
              </div>
              <div className="env-factors">
                <div>{goodWithText}</div>
                <div>{badWithText}</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

const isCurtsyListing = (petMedia: PhotoCardMedia) => {
  if (petMedia.isCourtesyListing) {
    return true;
  }
  const { name } = petMedia;
  if (name) {
    //PF doesn't have a courtesy listing flag, so we have to check the name
    const nameMatch =
      name.match(/courtesy/i) ||
      name.match(/guest/i) ||
      name.match(/posting/i) ||
      name.match(/cross post/i);
    return !!nameMatch;
  }
  return false;
};
